/* ./src/index.css */
@import url('https://fonts.cdnfonts.com/css/futura-md-bt');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

  .margen-image-login{
    margin-bottom: -160px;
    margin-top: -200px;
  }

  .background-siavi{
    background: rgb(23,16,82);
    background: linear-gradient(172deg, rgba(23,16,82,1) 19%, rgba(36,186,222,1) 120%);
  }

  .row-td {
    word-wrap: break-word;
  }

  .alto-pdf {
    height:50vh;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .z-index-select{
    z-index: 100;
  }
}